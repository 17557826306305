<template>
    <vs-card>
        <template slot="header">
            <div class="flex justify-between w-full">
                <h3 class="leading-normal"> Aftercare Docs </h3>
                <div class="flex justify-end space-x-2">
                    <vs-button @click="popup=true" type="flat" class="text-primary w-auto" > Preview </vs-button>
                    <vs-button type="filled" @click="$router.push({name: 'DoctorAftercareCreate'})"> + Add </vs-button>
                </div>
            </div>
        </template>
        
        <div v-if="welcomeBanner" class="m-4 p-4 text-base bg-gray-100 rounded-lg flex items-start lg:items-center justify-between space-x-2">
            <p class="flex-1">
              👋 Welcome to Fresh Clinics aftercare templates. There are a number of default templates available to edit or start using in treatments straight away! Otherwise, feel free to create a new one by pressing ‘Add’. 
            </p>
            <vs-button @click="closeWelcomeBanner" type="flat" class="flex items-center justify-center w-8 h-8 rounded-full border-none text-sm text-gray-600 bg-gray-300 cursor-pointer"> &#x2715; </vs-button>
        </div>

        <vs-table
            ref="table"
            :sst="true"
            :data="templates"
            class="templatesTable">

            <template slot="thead">
                <vs-th sort-key="firstName">Document</vs-th>
                <vs-th sort-key="lastName">Products</vs-th>
                <vs-th sort-key="email">Action</vs-th>
            </template>

           <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click.native="handleEditAftercare(tr._id)">
                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>
                    <vs-td :data="data[indextr].products">
                        <vx-tooltip  position="left" :text="displayProducts(data[indextr].products)">
                            <p class="clamp">{{ displayProducts(data[indextr].products) }}</p>
                        </vx-tooltip>
                    </vs-td>
                    <vs-td :data="data[indextr]._id">
                        <vs-button  :disabled="isPreviewLoading && indextr === selectedTemplateIndex" type="flat" @click.stop="preview(tr._id, indextr)">
                            <div class="flex flex-row items-center gap-2">
                                <vs-icon icon="refresh" class="animate-spin" v-if="isPreviewLoading && indextr === selectedTemplateIndex"></vs-icon>
                                <p>{{ isPreviewLoading && indextr === selectedTemplateIndex ? 'Loading...' : 'Preview' }}</p>
                            </div>
                        </vs-button>
                    </vs-td> 
                </vs-tr>
            </template>

        </vs-table>

        <vs-popup title="Preview" :active.sync="popup" @update:active="cancelRemovePopup">
           <div class="p-6 space-y-8">
                <div>
                    <p class="lg:text-xl md:text-lg text-base font-semibold">Select a template or multiple templates to preview</p>
                    <vs-select
                        multiple
                        autocomplete
                        v-model="selectedTemplates"
                        label="Select a template"
                        placeholder="Select a template"
                        class="w-full"
                    >
                        <vs-select-item
                            :key="item._id"
                            :value="item._id"
                            :text="item.name"
                            v-for="item in templates"
                        />
                    </vs-select>
                </div>
                <vs-button
                    :disabled="!selectedTemplates.length || isPreviewLoading"
                    type="border"
                    @click="preview(null, null)"
                >
                    <div class="flex flex-row items-center gap-2">
                        <vs-icon icon="refresh" class="animate-spin" v-if="isPreviewLoading"></vs-icon>
                        <p>Preview Custom Template(s)</p>
                    </div>
                </vs-button>
           </div>
        </vs-popup>

    </vs-card>
</template>

  
<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            welcomeBanner: false,
            popup: false,
            dataTableParams: {
                organizationId: ""
            },
            templates: [],
            isPreviewLoading: false,
            selectedTemplateIndex: null,
            selectedTemplates: []
        };
    },
    methods: {
        ...mapActions("aftercare", ["fetchTemplates", "previewAftercareTemplate"]),
        getList() {
            let self = this;
            this.fetchTemplates(self.dataTableParams).then(res => {
                self.templates = res.data.data;

                const allDefaultTrue = self.templates.every(template => template.isDefault === true);
                if (allDefaultTrue) this.welcomeBanner = true
            });
        },
        displayProducts(products) {
            if (Array.isArray(products) && products.length > 0) {
                return products.map(product => product.productName).join(", ");
            } else {
                return 'No products available';
            }
        },
        closeWelcomeBanner() {
            this.welcomeBanner = false
        },
        async preview(id, index) {
            try {
                this.isPreviewLoading = true;
                this.selectedTemplateIndex = index;
                let payload = {};
                if (id) {
                    payload = {
                        templateId: [id]
                    }
                }
                if (this.selectedTemplates.length) {
                    payload = {
                        templateId: this.selectedTemplates
                    }
                }
                const res = await this.previewAftercareTemplate(payload);
                if (res.status === 200) {  
                    window.open(res.data.data, '_blank');
                }
                this.isPreviewLoading = false;
                this.popup = false;
                this.selectedTemplates = [];
            } catch (error) {
                console.error(error);
                this.isPreviewLoading = false;
                this.popup = false;
            }
        },
        handleEditAftercare(id) {
            this.$router.push({name: "DoctorAftercareDetails", params: { id }});
        },
        cancelRemovePopup() {            
            this.selectedTemplates = [];
        }
    },
    created() {
        const organizationId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
        this.dataTableParams.organizationId = organizationId;
        this.getList();
    }
}
</script>

<style lang="scss">

.vs-card--content {
    padding: 0;
}

.vs-con-table .vs-con-tbody {
    border: none;
}

.clamp {
    width: 40rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.templatesTable {
    margin-top: 1rem;

    & .vs-table--td {
        padding: 0.5rem 0;

        button:not(.vs-button) {
            appearance: none;
            -webkit-appearance: none;
            border: none;
            background: none;
            font-size: 1rem;
            font-family: inherit !important;
            color: inherit !important;
            cursor: pointer;
        }
    }
}

/* Responsive <table> */
@media screen and (max-width: 600px) {
    .clamp {
        width: auto;
        -webkit-line-clamp: 1;
    }

    .templatesTable {

        & .vs-table {
            &--tbody-table {
                min-width: unset;
            }

            &--thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
                display: none !important;
            }

            &--tr {
                display: grid;
                grid-template-columns: 1fr 8rem;
                grid-template-rows: 1fr 1fr;
                border-bottom: 1px solid #eee;
                padding: 1rem 0;

                &:last-of-type {
                    border-bottom: none;
                }
            }
          
            &--td {
                display: block;
                padding: 0 1rem!important;

                & button:not(.vs-button) {
                    font-weight: 500;
                }
                
                &:first-child {
                    grid-column: 1 / 2;
                    grid-row: 1;
                    font-weight: 600;
                    color: black;
                }
                &:nth-child(2) {
                    grid-column: 1 / 2;
                    grid-row: 2;        
                }
                &:last-child {
                    grid-column: 2 / 3;
                    grid-row: 1 / 3;
                } 
            }
        }
    }
}
</style>
